import {StockInfo} from "../../../../../components/StockInfo/StockInfo";
import {Tooltip as ReactTooltip} from "react-tooltip";
import React from "react";
import {useTranslation} from "react-i18next";
import Image from "../../../../../components/Image/Image";
import RecommendedRetailPrice from "../../../../../components/RecommendedRetailPrice/RecommendedRetailPrice";
import {NumericFormat} from "react-number-format";
import RatePayInfo from "../../../../../components/RatePayInfo/RatePayInfo";
import {
    Link,
    useNavigate
} from "react-router-dom";
import { RimManufacturer } from "../../RimManufacturer";
import {RimViewCardProps} from "./RimCard";
import {useMediaQuery} from "../../../../../helper/useMediaQuery";
import SeasonIcon, {SeasonEnum} from '../../../../../components/SeasonIcon/SeasonIcon';
import {ReactComponent as FavoriteSvg} from '../../../../../assets/icons/svg/favorite.svg';

export default function ListViewRimCard(props: RimViewCardProps) {
    const {t} = useTranslation();
    const shortInfo = useMediaQuery("(max-width: 475px)");
    const navigate = useNavigate();
    const shrinkIcon = useMediaQuery("(max-width: 1350px)");

    function getTableRow(key: string, content: string | number | null, index: number) {
        return <div className={"additional-rim-info__row"} key={"rim-details-table-" + index}>
            <span>{t('RIMS.DETAILS.TABLE.' + key)}:&nbsp;</span>
            <span className="fw-bold">{content}</span>
        </div>
    }

    function getTableCol(colItem: { key: string, content: string | number | null }[]) {
        return <div className={"additional-rim-info__col"}>
            {colItem.map((item, index) => getTableRow(item.key, item.content, index))}
        </div>
    }

    function getRimInfo() {
        if (shortInfo) {
            return <span
                className={"fw-bold"}>{props.rim.width}x{props.rim.items[0]?.size} {t("COMPLETE_WHEEL.OFFSET")} {props.rim.offset}</span>
        }

        const rimInfosFirstCol: { key: string, content: string | number | null }[] = [
            {key: "SIZE", content: props.rim.items[0]?.size},
            {key: "WIDTH", content: props.rim.width},
            {key: "HOLE_COUNT", content: props.rim.holeCount}
        ];

        const rimInfosSecondCol: { key: string, content: string | number | null }[] = [
            {key: "HOLE_CIRCLE", content: props.rim.holeCircle},
            {key: "OFFSET", content: props.rim.offset},
            {key: "HUB_BORE_HOLE", content: props.rim.hubBoreHole},
        ];

        return (
            <div className="additional-rim-info">
                {getTableCol(rimInfosFirstCol)}
                {getTableCol(rimInfosSecondCol)}
            </div>
        )
    }

    function onClickHandler(evt, url) {
        props.selectRim()
        if (props.isSmallScreen) {
            props.detailsClicked(evt)
            navigate(url);
        }
    }

    function getDesktopCard() {
        return (
            <div
                className={`rim-card rim-card-list my-1 ${props.isSelected ? 'selected' : ''}`}
                id={props.rim.rimId} onClick={() => props.selectRim()}>

                <Image className="rim-card-list__image " src={props.rimImageSrc}
                       fallback="/no_images/felge.svg" alt={props.rim.manufacturer + " " + props.rim.name}/>

                <div className={"rim-card-list__icon-container"}>
                    {props.rim.winterproofed
                        ? <div className="winterproof me-2">
                            <SeasonIcon season={SeasonEnum.WINTER} size={shrinkIcon ? "1.5rem" : "2rem"}/>
                        </div>
                        : <div className={"me-2"} style={{width: shrinkIcon ? "1.5rem" : "2rem"}}>&nbsp;</div>
                    }
                    <div className={`fav-icon`}>
                        <FavoriteSvg height={'1.5rem'}
                                     onClick={(evt) => props.changeFavorite(evt)}
                                     color={props.isFavorite ? 'var(--bs-primary)' : 'var(--bs-gray-300)'}
                                     className={'cursor shadow-none'}
                                     data-tooltip-id={"favorite-" + props.rim.rimId}
                        />
                        <ReactTooltip id={"favorite-" + props.rim.rimId} place="bottom">
                            {props.isFavorite ? (
                                <span>{t("FAVORITE.TOOLTIPS.REMOVE_FROM_FAVORITES")}</span>
                            ) : (
                                <span>{t("FAVORITE.TOOLTIPS.ADD_TO_FAVORITES")}</span>
                            )}
                        </ReactTooltip>
                    </div>
                </div>

                <div className={`rim-card-list__heading rcl-heading__has-car-${!!props.carId}`}>
                    <h5 className={"text-dark m-0"}><RimManufacturer name={props.rim.manufacturer}/></h5>
                    <p className={"small m-0"}>{props.rim.name}</p>
                    <p className={"small"}>{props.rim.colourGroup}</p>
                </div>

                {props.carId
                    ? <div className={`rim-size-wrapper`}>{props.rimSizesList}</div>
                    : getRimInfo()
                }

                <div className={"rim-card-list__price"}>
                    <RecommendedRetailPrice
                        price={props.getPrice()}
                        recommendedRetailPrice={props.getRecommendedRetailPrice()}
                        hideIfEmpty
                    />
                    <h4 className="rim-price">
                        <span className={"rim-card-list-price__rim-descr"}>
                                    {t("RIMS.SEARCH.STARTING_AT")}&nbsp;</span>
                        <span className="text-highlight fw-bold">
                            <NumericFormat
                                value={props.getPrice() || t("RIMS.SEARCH.PRICE_BY_REQUEST")}
                                displayType="text"
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="€"
                            />
                        </span>
                    </h4>
                    <div>
                        <p className={"m-0 small"}>{t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount: 4})}</p>
                        <StockInfo className={"small mt-1"} deliveryStatus={props.deliveryStatus}/>
                        <RatePayInfo className={"small"}/>
                    </div>
                </div>

                <div className={`rim-card-list__button-bar `}>
                    <Link className={`btn btn-green`} to={props.rimDetailUrl}
                          onClick={(evt) => props.detailsClicked(evt)}>
                        {t("RIMS.SEARCH.DETAILS")}
                    </Link>
                    {props.carId &&
                        <button className={`btn btn-light`}
                                onClick={() => props.selectRim()}>
                            {t("RIMS.SEARCH.SHOW_ON_CAR")}
                        </button>
                    }
                </div>
            </div>

        );
    }

    function getMobileCard() {
        return (
            <div
                className={"rim-card rcl-mobile m-1 p-2" + (props.isSelected ? " selected" : "")}
                id={props.rim.rimId} onClick={(evt) => onClickHandler(evt, props.rimDetailUrl)}>
                <div className={"rcl-mobile__main-content"}>
                    <div className={`rcl-mobile-main-content__rim`}>
                        {props.rim.winterproofed
                            ? <div className="winterproof">
                                <SeasonIcon season={SeasonEnum.WINTER} size={'2rem'}/>
                            </div>
                            : <div style={{width: "28px"}}>&nbsp;</div>
                        }
                        <Image
                            className={"rcl-mobile-main-content-rim__image "}
                            src={props.rimImageSrc}
                            fallback="/no_images/felge.svg"
                            alt={props.rim.manufacturer + " " + props.rim.name}
                        />
                        <div className={`rcl-mobile-main-content-rim__fav-icon fav-icon`}>
                            <FavoriteSvg height={'1.5rem'}
                                         onClick={(evt) => props.changeFavorite(evt)}
                                         color={props.isFavorite ? 'var(--bs-primary)' : 'var(--bs-gray-300)'}
                                         className={'cursor shadow-none'}
                                         data-tooltip-id={"favorite-" + props.rim.rimId}
                            />
                            <ReactTooltip id="favorite" place="bottom">
                                {props.isFavorite ? (
                                    <span>{t("FAVORITE.TOOLTIPS.REMOVE_FROM_FAVORITES")}</span>
                                ) : (
                                    <span>{t("FAVORITE.TOOLTIPS.ADD_TO_FAVORITES")}</span>
                                )}
                            </ReactTooltip>
                        </div>
                    </div>
                    <div className={`rcl-mobile-main-content__heading`}>
                        <div>
                            <h3 className={`text-dark my-0`}><RimManufacturer name={props.rim.manufacturer}/></h3>
                            <p className={`small text-dark my-0`}>{props.rim.name}</p>
                            <p className={'small  color-line'}>{props.rim.colourGroup}</p>
                        </div>
                        {!props.carId && getRimInfo()}
                        <StockInfo className={"text-end small mt-1"} deliveryStatus={props.deliveryStatus}/>
                    </div>

                </div>

                <div className={"rcl-mobile__price"}>
                    <span>{t("RIMS.DETAILS.FOR_AMOUNT_RIMS", {amount: 4})}</span>
                    <h4 className={"mb-0"}>
                        <RecommendedRetailPrice
                            price={props.getPrice()}
                            recommendedRetailPrice={props.getRecommendedRetailPrice()}
                            hideIfEmpty
                        />
                        <div>
                            {t("RIMS.SEARCH.STARTING_AT")}&nbsp;
                            <span className="text-highlight fw-bold">
                                <NumericFormat
                                    value={props.getPrice() || t("RIMS.SEARCH.PRICE_BY_REQUEST")}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix="€"
                                />
                            </span>
                        </div>
                    </h4>
                </div>
            </div>
        )
    }

    return props.isSmallScreen ? getMobileCard() : getDesktopCard();
}
