import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ManufacturerLegalInfoDTO } from "../../models/shared/ManufacturerLegalInfoDTO";
import { getManufacturerLegalInfo } from "../../redux-store/api/ManufacturerLegalInfoApi";
import CustomModal from "../CustomModal/CustomModal";
import { LoadingAnimation } from "../LoadingAnimation/LoadingAnimation";
import { ManufacturerLegalInfo } from "./ManufacturerLegalInfo";

export function ManufacturerLegalInfoModal({ name, data: extData }: { name?: string, data?: ManufacturerLegalInfoDTO }) {
    const [data, setData] = useState<ManufacturerLegalInfoDTO>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [t] = useTranslation();

    function open() {
        if(extData){
            setData(extData)
        } else if (name && !data) {
            setIsLoading(true);
            getManufacturerLegalInfo(name)
                .then((res) => setData(res.data))
                .finally(() => setIsLoading(false));
        } else if(!name){
            setData(undefined)
        }
        setShow(true);
    }

    return (
        <>
            <button type={"button"} className={"btn btn-link btn-legal-info"} onClick={() => open()}>
                {t("MANUFACTURER_LEGAL_INFO.LINK")}
            </button>
            <CustomModal open={show} backdropClick={()=> setShow(false)}>
                <div className="custom-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="custom-modal-title">{t("MANUFACTURER_LEGAL_INFO.TITLE")}</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShow(false)}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <LoadingAnimation style={{ height: "90px" }} isLoading={isLoading}>
                                <ManufacturerLegalInfo data={data} />
                            </LoadingAnimation>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => setShow(false)}>
                                {t("MANUFACTURER_LEGAL_INFO.CLOSE")}
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    );
}
