import React from "react";
import { useTranslation } from "react-i18next";
import { ManufacturerLegalInfoDTO } from "../../models/shared/ManufacturerLegalInfoDTO";

export function ManufacturerLegalInfo({ data, collapsible }: { data: ManufacturerLegalInfoDTO; collapsible?: boolean }) {
    const [t] = useTranslation();

    function renderManufacturerLegalInfo() {
        return (
            <>
                {data ? (
                    <>
                        {data.companyName && <div className={"fw-bold"}>{data.companyName}</div>}
                        {data.companyAddress && <div>{data.companyAddress}</div>}
                        {data.contactPerson && (
                            <div>
                                <span className={"fw-bold"}>{t("MANUFACTURER_LEGAL_INFO.CONTACT_PERSON")} </span>
                                {data.contactPerson}
                            </div>
                        )}
                        {data.contactEmail && (
                            <div>
                                <span className={"fw-bold"}>{t("MANUFACTURER_LEGAL_INFO.CONTACT_EMAIL")} </span>
                                {data.contactEmail}
                            </div>
                        )}
                        {data.contactPhone && (
                            <div>
                                <span className={"fw-bold"}>{t("MANUFACTURER_LEGAL_INFO.CONTACT_PHONE")} </span>
                                {data.contactPhone}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="fw-medium">{t("MANUFACTURER_LEGAL_INFO.NOT_AVAILABLE")}</div>
                    </>
                )}
            </>
        );
    }

    return collapsible ? (
        <div className="expandable-information">
            <button
                className="btn btn-collapsible"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseManufacturerLegalInfo"
                aria-expanded="false"
                aria-controls="collapseManufacturerLegalInfo"
            >
                <div className="d-flex justify-content-between">
                    <div>{t("MANUFACTURER_LEGAL_INFO.TITLE")}</div>
                    <div>
                        <i className="fas fa-chevron-down" />
                    </div>
                </div>
            </button>
            <div className="collapse" id="collapseManufacturerLegalInfo">
                <div className="content">{renderManufacturerLegalInfo()}</div>
            </div>
        </div>
    ) : (
        renderManufacturerLegalInfo()
    );
}
