import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConstructionSeriesV3DTO } from "../../../models/car/ConstructionSeriesDTO";
import CarClassSelection from "../../CarClassSelection/CarClassSelection";
import CarHistorySelect from "../../CarHistorySelect/CarHistorySelect";
import CarModelSelection from "../../CarModelSelection/CarModelSelection";
import { CarSelectionState } from "../../CarSelection/SelectCarByManualSelection/SelectCarByManualSelection";
import ConstructionSeriesSelection from "../../ConstructionSeriesSelection/ConstructionSeriesSelection";
import ManufacturerSelection from "../../ManufacturerSelection/ManufacturerSelection";

interface Props {
    carHistoryEntryChosen?: Function;
    preSelectedManufacturer?: string;
    toggleLandingPagePicture?: Function;
    rimId: string;
    isRimDetailPage?: boolean;
}

function ManualSelectionMobile(props: Props) {
    const [t] = useTranslation();
    const [currentStep, setCurrentStep] = useState(CarSelectionState.MANUFACTURER);
    const [stepStates, setStepStates] = useState([]);
    const steps = [1, 2, 3, 4];
    const [selectedManufacturer, setSelectedManufacturer] = useState<string>(props.preSelectedManufacturer ?? "");
    const [selectedCarClass, setSelectedCarClass] = useState<string>("");
    const [selectedConstructionSeries, setSelectedConstructionSeries] = useState<ConstructionSeriesV3DTO>();
    const [isRimDetailPage, setIsRimDetailPage] = useState(false);

    const [showRenderScreen, setShowRenderScreen] = useState(false)

    useEffect(() => {

        const createdStepStates = steps.map((step, idx) => ({
            description: step,
            completed: idx * 10 < currentStep - 10, // past are completed
            selected: idx * 10 <= currentStep - 10 // past & present are colored
        }));

        props.toggleLandingPagePicture(currentStep == CarSelectionState.MANUFACTURER);

        setStepStates(createdStepStates);
    }, [currentStep]);

    useEffect(() => {
        if (props.preSelectedManufacturer) {
            const correctedName = titleCase(props.preSelectedManufacturer);
            setSelectedManufacturer(correctedName);
            setCurrentStep(CarSelectionState.CAR_CLASS);
        }
    }, [props.preSelectedManufacturer]);


    function titleCase(input: string) {
        if (input.toLowerCase() == "bmw" || input.toLowerCase() == "vw") {
            return input.toUpperCase();
        }
        return input[0].toUpperCase() + input.slice(1).toLowerCase();
    }

    function onSelectedManufacturer(manufacturer: string) {
        setSelectedManufacturer(manufacturer);
        setCurrentStep(CarSelectionState.CAR_CLASS);
    }

    function onSelectedCarClass(carClass: string) {
        setSelectedCarClass(carClass);
        setCurrentStep(CarSelectionState.CONSTRUCTION_SERIES);
    }

    function onSelectedConstructionSeries(constructionSeries: ConstructionSeriesV3DTO) {
        setSelectedConstructionSeries(constructionSeries);
        setCurrentStep(CarSelectionState.CAR_MODEL_SELECTION);
    }


    function loadStep(idx: number, selected: boolean) {
        if (!selected) {
            return;
        }

        setCurrentStep(idx * 10 as CarSelectionState);
    }

    function renderScreen() {
        switch (currentStep) {
            case CarSelectionState.MANUFACTURER:
                return (<div>
                    <ManufacturerSelection manufacturerSelected={onSelectedManufacturer} isLandingPage={false} />
                </div>);
            case CarSelectionState.CAR_CLASS:
                return (<div><CarClassSelection selectedManufacturerId={selectedManufacturer}
                                                carClassSelected={onSelectedCarClass}
                                                goBack={() => setCurrentStep(CarSelectionState.MANUFACTURER)} /></div>);
            case CarSelectionState.CONSTRUCTION_SERIES:
                return (<div><ConstructionSeriesSelection selectedManufacturerId={selectedManufacturer}
                                                          selectedCarClassId={selectedCarClass}
                                                          constructionSeriesSelected={onSelectedConstructionSeries}
                                                          goBack={() => setCurrentStep(CarSelectionState.CAR_CLASS)} />
                </div>);
            case CarSelectionState.CAR_MODEL_SELECTION:
                return (<div><CarModelSelection selectedManufacturerId={selectedManufacturer}
                                                selectedCarClassId={selectedCarClass}
                                                constructionSeries={selectedConstructionSeries}
                                                isManualSelection={true}
                                                rimId={props.rimId}
                                                isRimDetailPage={props.isRimDetailPage}
                                                goBack={() => setCurrentStep(CarSelectionState.CONSTRUCTION_SERIES)} />
                </div>);
            default:
                break;
        }
    }

    return (
        <div className="manual-selection-mobile">
            <CarHistorySelect optionSelected={props.carHistoryEntryChosen}/>
            {!showRenderScreen && <button className={'btn btn-primary w-100'} onClick={() => setShowRenderScreen(true)}>{t('CAR_SELECTION.BTN.CONFIGURE_VEHICLE')}</button>}

            {showRenderScreen && renderScreen()}
        </div>
    );
}

export default ManualSelectionMobile;
