import React from "react";
import { useTranslation } from "react-i18next";

export function RimManufacturer({ name }: { name: string }) {
    const { t } = useTranslation();
    if (name === "STEEL_RIM") {
        return <>{t("INPUT.TRANSLATE_VALUES.STEEL_RIM")}</>;
    }
    return <>{name}</>;
}
