import {Field, Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AnalyticsHelper} from "../../../analytics/AnalyticsHelper";
import {CartDTO, PaymentMethod} from "../../../models/cart/CartDTO";
import CustomModal from "../../../components/CustomModal/CustomModal";
import Tooltip from "rc-tooltip";
import CartPrice from "../../ShoppingCart/components/CartPrice/CartPrice";
import RedeemVoucher from "../../ShoppingCart/components/RedeemVoucher/RedeemVoucher";
import AcceptedPaymentMethods from "../../ShoppingCart/components/AcceptedPaymentMethods/AcceptedPaymentMethods";
import {useSelector} from "react-redux";

interface Props {
    previousStep?: Function;
    selectedPaymentMethod: PaymentMethod | "";
    submit: (paymentMethod: PaymentMethod) => void;
    cart?: CartDTO;
    updateCartHandler?: Function;
}

export const paymentMethods = [
    {
        type: PaymentMethod.PRE_PAYMENT,
        icon: "/payment/vorkasse.png"
    },
    {
        type: PaymentMethod.PAY_PAL,
        icon: "/payment/paypal.png"
    },
    {
        type: PaymentMethod.PICKUP,
        icon: "/payment/wc-money-payment.png"
    }
];

function PaymentAndShipping({previousStep, selectedPaymentMethod, submit, cart, updateCartHandler}: Props) {
    const {t} = useTranslation();
    const [showPaymentMethodInformation, setShowPaymentMethodInformation] = useState<PaymentMethod>()


    function goBackToPreviousStep() {
        previousStep();
    }

    return (
        <div className="row">
            {previousStep &&
                <div className="col-12 text-end col-lg-11 offset-lg-1">
                    <div className="d-flex align-items-baseline mb-3 ms-3" onClick={() => goBackToPreviousStep()}>
                        <i className="fas fa-long-arrow-alt-left"/>
                        <div className="ms-2 cursor">{t("CHECKOUT.PAYMENT_OPTIONS.BACK_TO_CUSTOMER_DATA")}</div>
                    </div>
                </div>
            }
            <div className="col-12 col-lg-6 offset-0 offset-lg-1">
                <div className="panel">
                    <div className="heading">
                        {t("CHECKOUT.PAYMENT_OPTIONS.PLEASE_CHOOSE_PAYMENT_OPTION")}
                    </div>
                    <div className="payment-wrapper col-12 mb-3">
                        <Formik
                            initialValues={{
                                paymentMethod: selectedPaymentMethod || PaymentMethod.PRE_PAYMENT,
                            }}
                            onSubmit={({paymentMethod}) => {
                                submit(paymentMethod)
                                AnalyticsHelper.trackPaymentMethod(paymentMethod, cart);
                            }}
                        >
                            {({values}) => (
                                <Form>
                                    <div>
                                        {paymentMethods.map((paymentMethod) => {
                                                return <div className="payment-method-container" key={paymentMethod.type}>
                                                    <label className="form-check-label d-flex align-items-center"
                                                           htmlFor={paymentMethod.type}>
                                                        <div className="form-check">
                                                            <Field
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="paymentMethod"
                                                                id={paymentMethod.type}
                                                                value={paymentMethod.type}
                                                            />
                                                            <span className="ms-2 fw-bold">
                                                            {t("ENUMS.PAYMENT_METHODS." + paymentMethod.type)}
                                                        </span>
                                                    </div>
                                                    <div className="icon-container">
                                                        <img src={paymentMethod.icon} alt={paymentMethod.type}/>
                                                    </div>
                                                </label>
                                            </div>
                                        }
                                        )}
                                    </div>
                                    <div className="col-12 mt-5 text-end">
                                        <button type="submit" className="btn btn-green w-100">
                                            {t("CHECKOUT.PAYMENT_OPTIONS.CONTINUE_TO_SUMMARY")}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="panel">
                    <RedeemVoucher updateCart={(cart) => updateCartHandler(cart)}
                                   cart={cart}/>
                </div>
                <div className="panel">
                    <CartPrice sumNet={cart.sumNet}
                               sumGross={cart.sumGross}
                               vat={cart.vat}
                               discount={cart.discount > 0 ? cart.discount : null}
                               shipping={cart.shipping > 0 ? cart.shipping : null}
                    />
                </div>
                <div className="panel">
                    <AcceptedPaymentMethods/>
                </div>
            </div>
            <CustomModal open={!!showPaymentMethodInformation}>
                <div className="custom-modal car-details-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="custom-modal-title d-inline-block">
                                {t("PAYMENT_METHOD_INFORMATION.TITLE")}
                            </h5>
                            <button type="button" className="btn-close"
                                    onClick={() => setShowPaymentMethodInformation(undefined)} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            {t('PAYMENT_METHOD_INFORMATION.' + showPaymentMethodInformation)}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={`btn btn-primary`}
                                    onClick={() => setShowPaymentMethodInformation(undefined)}>
                                {t("PAYMENT_METHOD_INFORMATION.CLOSE")}
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
}

export default PaymentAndShipping;
