import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import useCookieScript from "use-cookiescript-hook";
import { ErrorBoundary } from "../../helper/ErrorBoundary";
import { RedirectWithStatus } from "../../helper/RedirectWithStatus";
import useFullPageLoader from "../../helper/useFullPageLoader";
import { useTracking } from "../../helper/useTracking";
import { redirects } from "../../redirects";
import { routes } from "../../routes";
import ScrollToTop from "../ScrollToTop";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import { PullOutMenu } from "./PullOutMenu/PullOutMenu";

function Layout() {
    useTracking();
    let location = useLocation();
    global.LOAD = useFullPageLoader();
    useCookieScript("//cdn.cookie-script.com/s/e624ee60dec321b4fa095d63bc174068.js", {});

    return (
        <div className="body-wrapper">
            <div className="header-container" id="header">
                <Header loc={location.pathname} />
            </div>
            <div className="body-container">
                <ErrorBoundary>
                    <Routes>
                        {redirects.map(redirect => <Route
                            key={redirect.from}
                            path={redirect.from}
                            element={<RedirectWithStatus {...redirect} />}
                        />)}
                        {routes.map((route) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={route.preventScrollTop ? React.createElement(route.component) : <>
                                    <ScrollToTop />
                                    {React.createElement(route.component)}
                                </>}
                            />
                        ))}
                        <Route
                            path={"*"}
                            element={React.createElement(NotFoundPage)}
                        />
                    </Routes>
                </ErrorBoundary>
            </div>
            <PullOutMenu></PullOutMenu>
            <Footer />
        </div>
    );
}

export default Layout;
