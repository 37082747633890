import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";
import {ReactComponent as DeleteSvg} from "../../assets/icons/svg/delete.svg";
import {getFallbackImage} from "../../helper/Helper";
import {
    CartDTO,
    CartPositionDTO
} from "../../models/cart/CartDTO";
import {
    Axle,
    ItemType
} from "../../models/shared/Enums";
import { WcsLegalInfoDTO } from "../../models/shared/ManufacturerLegalInfoDTO";
import {
    updatePositionQuantity,
    updatePositionTpms
} from "../../redux-store/api/ShoppingCartApi";
import AmountInput from "../AmountInput/AmountInput";
import Image from "../Image/Image";
import CarBuildDateInput from '../../pages/ShoppingCart/components/CarBuildDateInput/CarBuildDateInput';
import CarDescriptionInput from '../../pages/ShoppingCart/components/CarDescriptionInput/CarDescriptionInput';
import { ManufacturerLegalInfoModal } from "../ManufacturerLegalInfo/ManufacturerLegalInfoModal";
import TyreEuLabel from '../TyreEuLabel/TyreEuLabel';
import {TpmsItem} from '../../models/car/TpmsCheckDTO';
import {checkForTpms} from '../../redux-store/api/CarApi';
import {ShoppingCartContext} from '../../pages/ShoppingCart/ShoppingCart';
import {ReactComponent as InfoSvg} from '../../assets/icons/svg/info.svg';

interface Props {
    position: CartPositionDTO;
    updateCart?: (cart: CartDTO) => void;
    showInputs: boolean;
    removeButtonHandler?: Function;
    getAdditionalTyreForRimInCart?: Function;
    onlyEvenNumbers: boolean;
}

function CartPosition({
                          position,
                          updateCart,
                          showInputs,
                          removeButtonHandler,
                          getAdditionalTyreForRimInCart,
                          onlyEvenNumbers
                      }: Props) {
    const [t] = useTranslation();
    const [quantity, setQuantity] = useState(position.quantity);
    const [tpmsItems, setTpmsItems] = useState<TpmsItem[]>([]);
    const [noTpmsItem, setNoTpmsItem] = useState<TpmsItem>();
    const [tpmsRequired, setTpmsRequired] = useState(false);

    const {
        showLoader,
        hideLoader
    } = useContext(ShoppingCartContext);

    useEffect(() => {
        if(position.items.find(i => (i.itemType === ItemType.TPMS || i.itemType === ItemType.NO_TPMS))) {
            checkForTpms(position.carId).then((res) => {
                setTpmsItems(res.items);
                setNoTpmsItem(res.noTpmsItem);
                setTpmsRequired(res.tpmsRequired);
            });
        }
    }, []);

    function getAdditionalTyre(rimId: string, certificateId: number, certificateBlockId: number) {
        getAdditionalTyreForRimInCart(rimId, certificateId, certificateBlockId, position.carId, position.quantity, position.id, position.boltCircle);
    }

    function changeQuantity(newQuantity: number) {
        showLoader();
        setQuantity(newQuantity);
        updatePositionQuantity(position, newQuantity).then((cart) => {
            if(updateCart) {
                updateCart(cart);
            }
            hideLoader();
        }).catch((err) => {
            console.error(err);
            hideLoader();
        });
    }

    function onChangeTpms(tpms) {
        showLoader();
        updatePositionTpms(position, tpms).then((cart) => {
            if(updateCart) {
                updateCart(cart);
            }
            hideLoader();
        }).catch((err) => {
            console.error(err);
            hideLoader();
        });
    }

    return (<div className="cart-position">
        {position.items.map((item, index, arr) => {
            if((item.itemType === ItemType.TPMS || item.itemType === ItemType.NO_TPMS) && !tpmsRequired) {
                // When TPMS is not required
                return;
            }
            return <div key={item.id}>
                <title className={'mb-3'}>{t('CART.ITEM_TYPE.' + item.itemType)}</title>

                {showInputs && (item.itemType === ItemType.TPMS || item.itemType === ItemType.NO_TPMS) && tpmsItems && <div className={'d-flex gap-3 mb-3 mx-3'}>
                    {tpmsItems.map(tpmsItem => <div key={tpmsItem.productId}>
                        <input
                            type="radio"
                            className="radio-button"
                            checked={tpmsItem.productId.toString() === item.productId}
                            onChange={() => onChangeTpms(tpmsItem)}
                            name={"tpms-" + item.id}
                            id={position.id+'-'+tpmsItem.productId.toString()}
                        />
                        <label className="radio-button-label ms-1" htmlFor={position.id+'-'+tpmsItem.productId.toString()}>
                            {tpmsItem.label}
                        </label>
                    </div>)}

                    {noTpmsItem && <div>
                        <input
                            type="radio"
                            className="radio-button"
                            checked={noTpmsItem.productId.toString() === item.productId}
                            onChange={(e) => onChangeTpms(noTpmsItem)}
                            name="tpms"
                            id={position.id+'-'+noTpmsItem.productId.toString()}
                        />
                        <label className="radio-button-label ms-1" htmlFor={position.id+'-'+noTpmsItem.productId.toString()}>
                            {t('TPMS.DO_NOT_ADD_TPMS_OPTION')}
                        </label>
                    </div>}
                </div>}

                <div className={'d-flex align-items-center mb-3 mx-3 gap-4'}>
                    {item.itemType !== ItemType.MOUNTINGKIT && item.itemType !== ItemType.WHEELASSEMBLY && <Image src={item.imageLink}
                                                                                                                  fallback={getFallbackImage(item)}
                                                                                                                  alt={item.description}
                                                                                                                  fluid={true}
                                                                                                                  width={'150px'}
                    />}
                    <div className="item-description">
                        {item.itemType !== ItemType.WHEELASSEMBLY && item.manufacturer && <div className="item-title">
                            {item.manufacturer}
                        </div>}
                        {item.itemType === ItemType.RIM && <b>{item.productMetadata?.rimType}</b>}
                        {item.itemType === ItemType.TYRE && <b>{item.productMetadata?.tread}</b>}
                        {(item.itemType === ItemType.TPMS || item.itemType === ItemType.NO_TPMS) && <b>{item.description}</b>}
                        {item.itemType === ItemType.TPMS && <div><ManufacturerLegalInfoModal data={WcsLegalInfoDTO}/></div>}
                        {(item.itemType === ItemType.MOUNTINGKIT || item.itemType === ItemType.WHEELASSEMBLY) && <>{item.description}</>}

                        {item.itemType === ItemType.RIM && <>
                            <div
                                className="mb-3">{`${item.productMetadata.width}x${item.productMetadata.size} ET${item.productMetadata.offset}, ${item.productMetadata.boltPattern}`}</div>
                            <div>{item.productMetadata.colorGroup}</div>
                        </>}

                        {item.itemType === ItemType.TYRE && <>
                            <div className="mb-3">
                                {`${item.productMetadata.width}/${item.productMetadata.section}R${item.productMetadata.diameter} ${item.productMetadata.loadIndex}${item.productMetadata.speedIndex}`}
                            </div>
                            <div>{item.productMetadata.season ? t("TYRES.SEARCH.SEASONS." + item.productMetadata.season) : '&nbsp;'}</div>
                        </>}

                        {(showInputs && item.itemType === ItemType.TPMS) && <CarBuildDateInput item={item}/>}

                        {+item.productId > 0 && item.productEan && <div>{t("CART.ARTICLE_NUMBER")}:&nbsp;{item.productEan}</div>}

                        {(position.axle === "FRONT" || position.axle === "REAR") && (<div>{t("CART.AXLE." + position.axle)}</div>)}

                        {!showInputs && (item.itemType === ItemType.TPMS || item.itemType === ItemType.NO_TPMS) && <div className="mt-3">
                            {t("CART.CAR_BUILD_DATE_PLACEHOLDER")}: {item.carBuildDate}
                        </div>}

                        {item.errorMessage && <div className="text-danger mt-2">{item.errorMessage}</div>}
                        {!item?.errorMessage && (!!item.priceGross) && (item.priceGross > 0) && <div className="d-flex justify-content-between mt-4">
                            <div className="">{t("CART.PIECE_PRICE")}</div>
                            <strong>
                                <NumericFormat
                                    value={item.priceGross}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix="€"
                                />
                            </strong>
                        </div>}

                        {item.tyreEULabel && <TyreEuLabel tyreEULabel={item.tyreEULabel}/>}

                        {showInputs && position.positionType === ItemType.RIM && position.carId && position.axle === Axle.BOTH && <div>
                            <button
                                type="button"
                                className="btn btn-primary w-100 mt-3"
                                onClick={() => getAdditionalTyre(item.productExternalId, item.productMetadata.certificateId, item.productMetadata.certificateBlockId)}
                            >
                                {t("CART.ADD_TYRE")}
                            </button>
                        </div>}
                    </div>
                    {showInputs && position.positionType === ItemType.RIM && !position.carHsn && <CarDescriptionInput position={position}/>}
                    {!showInputs && position.positionType === ItemType.RIM && !position.carHsn && <div className="car-description text-start mt-2">
                        {t("CART.CAR_DESCRIPTION")}: {position.carDescription}
                    </div>}
                </div>
                {item.itemType === ItemType.RIM && <div className="mb-3 mx-3 info-message">
                    <InfoSvg height={'1.5rem'} className={'text-highlight me-2'}/>
                    {t("CART.RIM_INCLUDES_SCREWS")}
                </div>}
            </div>
        })}
        <hr/>
        <div className={'d-flex justify-content-between'}>
            {showInputs && <button className="icon-btn"
                                   onClick={() => removeButtonHandler(position)}
            >
              <DeleteSvg height={'1.5rem'} color={'primary'} className={'me-2'}/> {t('CART.REMOVE_POSITION')}
            </button>}
            <div className={'d-flex flex-column align-items-end'}>
                <AmountInput
                    amount={quantity}
                    handleAmountChange={(amount) => changeQuantity(amount)}
                    onlyEvenNumbers={onlyEvenNumbers}
                    readonly={!showInputs}
                />
                {onlyEvenNumbers && <span className={"small"}>{t('CART.ONLY_EVEN_NUMBERS_ALLOWED')}</span>}
            </div>

        </div>
        <hr/>
        <div className={'d-flex justify-content-between'}>
            <b>{t('CART.SUB_TOTAL')}</b>
            <div className="item-price">
                {position?.sumGross && (<NumericFormat
                    value={position.sumGross}
                    displayType="text"
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    suffix="€"
                />)}
            </div>
        </div>
    </div>);
}

export default CartPosition;
