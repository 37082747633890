import {CarViewRimPreview} from "./CarViewRimPreview";
import {RimConfigComponent} from "../../bmf3dImages/components/rim-config.component";
import CarViewConfigPanel from "./carViewConfigOptions/CarViewConfigPanel";
import CustomModal from "../CustomModal/CustomModal";
import CarDetailsModal from "../CarDetailsModal/CarDetailsModal";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {SubCarViewProps} from "./CarView";
import {Orientation, useOrientation} from "../../helper/useOrientation";


export default function DesktopCarView(props: SubCarViewProps) {

    const [t] = useTranslation();
    const orientation = useOrientation();
    const [isButtonBarExpanded, setIsButtonBarExpanded] = useState<boolean>(false);

    return (<>
            {/*/!*DEBUG INFO*!/*/}
            {/*{"DESKTOP:"}*/}
            {/*{orientation.type}*/}
            {/*{' fullscreen: ' + props.fullscreen.toString()}*/}
            {/*/!*{'smallScreen: ' + props.isSmallScreen.toString()}*!/*/}

            <div id="car-view"
                 className={`background  ${props.selectedBackground} ${(props.isSmallScreen && orientation.type === Orientation.LANDSCAPE && props.fullscreen) ? 'fullscreen' : ''}`}
                 onClick={(evt) => props.closeConfigOnOutsideClick(evt)}>

                {props.selectedRimGroup && props.selectedRimGroup.rimId &&
                    <CarViewRimPreview selectedRimId={props.rimId} rimGroup={props.selectedRimGroup}
                                       changeRimSize={props.changeRimSize}
                                       carId={props.carId}/>
                }

                {props.carId &&
                    <div onClick={() => setIsButtonBarExpanded(false)}>
                        <RimConfigComponent
                        key={
                            props.carId + '-' + props.use3d
                        }
                            vehicleId={props.carId}
                            hideColorSelect={true}
                            rimId={props.rimId}
                            use3d={props.use3d}
                            carColor={props.selectedColorId}
                            lowering={props.selectedLowering}
                            setCanBeLowered={(can) => props.setCanBeLowered(can)}
                            imageLoaded={(data) => props.imageLoaded(data)}
                        />
                    </div>
                }

                {props.isSmallScreen && !props.fullscreen &&
                    <i className="position-absolute top-0 mt-5 pt-2 text-black fas fa-expand fa-2x"
                       onClick={() => props.toggleFullScreenMode()}/>
                }

                {!props.isSmallScreen &&
                    <CarViewConfigPanel carBackgrounds={props.carBackgrounds}
                                        selectedColorId={props.selectedColorId}
                                        selectedBackground={props.selectedBackground}
                                        selectedLowering={props.selectedLowering}
                                        loweringMin={props.loweringMin}
                                        loweringMax={props.loweringMax}
                                        openCarDetails={props.openCarDetails}
                                        editCar={props.editCar}
                                        isExpanded={isButtonBarExpanded}
                                        isExpandedCallback={(state) => setIsButtonBarExpanded(state)}
                                        carColors={props.carColors}
                                        isSmallScreen={false}
                                        onOptionChangeHandler={(option, obj) => props.onChangeOptionHandler(option, obj)}/>
                }
                <CustomModal open={props.showCarDetails}>
                    <CarDetailsModal close={() => props.toggleCarDetails()}
                                     carImageUrl={props.carImageUrl}
                                     rimConfigData={props.rimConfigData}
                                     carDetails={props.carDetails}
                                     selectedColorId={props.selectedColorId}/>
                </CustomModal>
            </div>
        </>
    );
}
