import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { showWinterImage } from "../../helper/Helper";
import HsnTsnSelectionMobile from "./HsnTsnSelectionMobile/HsnTsnSelectionMobile";
import ManualSelectionMobile from "./ManualSelectionMobile/ManualSelectionMobile";

interface Props {
    isRimDetailPage: boolean;
    rimId?: string;
    preSelectedManufacturer?: string;
    backgroundImage?: string;
    headline?: string;
    isOnSeoPage?: boolean;
    isLandingPage?: boolean;
}

function CarSelectionMobile({
                                isRimDetailPage,
                                rimId,
                                headline,
                                preSelectedManufacturer,
                                isOnSeoPage,
                                isLandingPage
                            }: Props) {
    const [t] = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("manualSelection");
    const [isLandingPageView, setIsLandingPageView] = useState(isLandingPage);
    const [showLandingPagePicture, setShowLandingPagePciture] = useState<boolean>(true);

    function goToRimConfigurator(e) {
        const carId = e.value;
        const searchParams = new URLSearchParams(location.search).toString();
        if (isRimDetailPage && rimId) {
            navigate({
                pathname: "/felgen/details/" + encodeURIComponent(rimId) + "/" + encodeURIComponent(carId),
                search: searchParams
            });
        } else {
            navigate({
                pathname: "/felgenkonfigurator/" + encodeURIComponent(carId),
                search: searchParams
            });
        }
    }

    function toggleLandingPagePicture(value: boolean) {
        setShowLandingPagePciture(value);
    }

    return (
        <>
            {isLandingPageView && showLandingPagePicture && !preSelectedManufacturer &&
                <div className={`container-fluid px-0 `}>
                    <img className={`img-fluid ${isLandingPageView ? "m-0" : "mb-2"}`}
                         src={`${showWinterImage() ? "winter.jpg" : "/home/car-selection-car-image.jpg"}`} alt="car" />
                </div>
            }
            <div id={"car-selection-mobile"} className={`car-selection-mobile m-3`}>
                <ul className="nav nav-tabs car-selection-list">
                    <li className={`car-selection-header ${(activeTab === "manualSelection" ? "active" : "")}`}>
                        <a onClick={() => setActiveTab("manualSelection")}
                           className={activeTab === "manualSelection" ? "active" : ""}>
                            {t("CAR_SELECTION.BY_MANUAL.TAB")}
                        </a>
                    </li>
                    <li className={`car-selection-header ${(activeTab === "hsnTsn" ? "active" : "")}`}>
                        <a onClick={() => setActiveTab("hsnTsn")}
                           className={activeTab === "hsnTsn" ? "active" : ""}>
                            {t("CAR_SELECTION.BY_HSN_TSN.TAB")}
                        </a>
                    </li>
                </ul>

                <div className="bg-milky mt-3">
                    <div id="manualSelection"
                         className={"tab-pane " + (activeTab === "manualSelection" ? "active" : "")}>
                        {activeTab === "manualSelection" &&
                            <ManualSelectionMobile carHistoryEntryChosen={goToRimConfigurator}
                                                   preSelectedManufacturer={preSelectedManufacturer}
                                                   rimId={rimId}
                                                   isRimDetailPage={isRimDetailPage}
                                                   toggleLandingPagePicture={(value) => toggleLandingPagePicture(value)} />
                        }
                    </div>
                    <div id="hsnTsn" className={"tab-pane " + (activeTab === "hsnTsn" ? "active" : "")}>
                        {activeTab === "hsnTsn" &&
                            <HsnTsnSelectionMobile
                                carHistoryEntryChosen={goToRimConfigurator}
                                headline={headline}
                                isRimDetailPage={isRimDetailPage}
                                rimId={rimId}
                                isOnSeoPage={isOnSeoPage} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default CarSelectionMobile;
