export interface ManufacturerLegalInfoDTO {
    companyName: string;
    companyAddress: string;
    contactPerson: string;
    contactEmail: string;
    contactPhone: string;
}
export const WcsLegalInfoDTO: ManufacturerLegalInfoDTO = {
    companyName: "Wheelscompany GmbH",
    companyAddress: "Carl-von-Linde-Str. 6, 27367 Sottrum",
    contactPerson: "Niklas Klein, Stefan Klein",
    contactEmail: "info@wheelscompany.com",
    contactPhone: "(+49) 04264 77693 0"
}
